import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../../layouts/secondary-landing";
import HeroChevron from "../../../../components/hero/hero-chevron";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";
import ContactInfo from "../../../../components/contact-info/contact-info";

//Helpers
import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";

const SaveAtSchool = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/save-at-school/hero-save-at-school-01-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/save-at-school/hero-save-at-school-01-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/save-at-school/hero-save-at-school-01-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/save-at-school/hero-save-at-school-01-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/save-at-school/hero-save-at-school-01-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/save-at-school/hero-save-at-school-01-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/save-at-school/hero-save-at-school-01-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "save-at-school-hero",
    ...getHeroImgVariables(imgData),
    altText: "Happy schoolkids celebrating",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Start a Save at School program at your school!"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "Contact Us",
            class: "btn-white",
            url: "/about-us/contact-us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/savings-account",
      title: "Savings, CDs & Money Markets"
    },
    {
      id: 3,
      url: "/personal-banking/savings-account/youth-savings-account",
      title: "Youth Savings Account"
    },
    {
      id: 4,
      active: true,
      title: "Save at School"
    }
  ];
  const title = "Save at School Program",
    description =
      "Learn how kids can save at school with WaFd Bank's Save at School account. Help kids with financial literacy and find out how WaFd helps grow your child's savings.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/savings-account/youth-savings-account/save-at-school"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-save-at-school-01-250.jpg"
      }
    ],
    schema: {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Will my child get an ATM card?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, at the request of the custodian/parent/legal guardian."
          }
        },
        {
          "@type": "Question",
          name: "Are ATM cards different than debit cards?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, ATM cards cannot be used to make store or online purchases."
          }
        },
        {
          "@type": "Question",
          name: "Is there an age requirement for access to WafdBank.com?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The Child Online Privacy Protection Act (COPPA) restricts the collection of personal information from children for the purpose of website use. For this reason, clients under age 13 cannot sign up for online banking access."
          }
        },
        {
          "@type": "Question",
          name: "Can my child get text alerts about their balance?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Online Banking offers the ability to setup various alert types that can be sent via email or text. Clients over the age of 13 are eligible to enroll in Online Banking and get alerts."
          }
        },
        {
          "@type": "Question",
          name: "How do I change my child's ATM card PIN?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "If you've forgotten or need to change your ATM card PIN please call 866-661-8550."
          }
        },
        {
          "@type": "Question",
          name: "What should I do if my child's card is lost, damaged or stolen?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "If you're child has lost their ATM card or suspect it might be stolen, then we'll quickly disable your old card and get you a new one. You may call your local branch, or our Client Care Center is available at 800-324-9375."
          }
        },
        {
          "@type": "Question",
          name: "How does my child get online access?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Children over the age of 13 can enroll in online banking by visiting wafdbank.com or downloading the WaFd Bank app."
          }
        },
        {
          "@type": "Question",
          name: "How can my child deposit checks and cash?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Children can deposit checks or cash by using WaFd Bank ATMs (if they have an ATM card), visiting their local branch or using mobile deposit (if over 13 and enrolled in online/mobile banking)"
          }
        },
        {
          "@type": "Question",
          name: "Can people other than parents make deposits to this account?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Account owners (children, parents, legal guardians or custodians) can make deposits. Restrictions may apply for other individuals."
          }
        },
        {
          "@type": "Question",
          name: "Will my child's savings account need to be reported on my taxes?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "At WaFd Bank, the minor account is opened under the child's Social Security Number. If interest income is reported to IRS, a 1099-INT will be issued under the child's name. For more assistance, please consult with a tax specialist."
          }
        },
        {
          "@type": "Question",
          name: "Can my child make ATM withdrawals from their savings account?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "If an ATM card is requested and issued, withdrawals can be made at WaFd Bank ATMs or at over 40,000 MoneyPass ATMs."
          }
        }
      ]
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <h1>Save at School</h1>
            <h4>
              At WaFd Bank, we've been helping our neighbors save for the future since 1917...over 100 years. Partner
              with WaFd Bank to help teach our kids how to save money with a "Save at School" program:
            </h4>
            <ul>
              <li>
                <div className="font-weight-bold text-green-60">Just $10 deposit opens a new account</div>
                Accounts must be opened in person, with a parent or legal guardian, at a branch or at the school on
                designated "Bank Days" or Save at School presentation days.
              </li>
              <li>
                <div className="font-weight-bold text-green-60">We add another $5</div>
                With initial deposit, we add another $5 to each brand new account!
              </li>
              <li>
                <div className="font-weight-bold text-green-60">"Bank Days" cash deposits</div>
                Kids can make cash deposits of up to $20 at school on "Bank Days."
              </li>
              <li>
                <div className="font-weight-bold text-green-60">No fees, no minimum balance to earn interest</div>
                Maximum account balance limited to $2,000 monthly
              </li>
              <li>
                <div className="font-weight-bold text-green-60">Earn 1% interest</div>
              </li>
              <li>
                <div className="font-weight-bold text-green-60">Track deposits with Online Banking</div>
                Students can keep track of their deposits with their own register and in online banking!
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../../../images/kids-with-walt.jpg"
              alt="happy schoolkids with hands raised with Walt"
              placeholder="blurred"
              quality="100"
              style={{ borderBottom: "10px solid #008733" }}
            />
          </div>
        </div>
      </section>
      <ContactInfo />
    </SecondaryLanding>
  );
};

export default SaveAtSchool;
